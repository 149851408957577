import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Login from '../pages/Login';

import { AuthProvider } from '../context/auth';

import Operation from '../pages/Operation';
import History from '../pages/History';
import Extract from '../pages/Extract';

const Routes = () => {
  return (
    <Switch>
      <AuthProvider>
        <Route path="/" exact component={Login} />
        <Route path="/operation" isPrivate component={Operation} />
        <Route path="/history" isPrivate component={History} />
        <Route path="/extract" isPrivate component={Extract} />
      </AuthProvider>
    </Switch>
  );
};

export default Routes;
