import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { useAuth } from '../../context/auth';

import {
  Container,
  Content,
  HeaderClient,
  HeaderPassword,
  FinishPurchase,
  Footer,
} from './styles';
import logo from '../../assets/images/fav.png';

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useAuth();
  const [mensagem, setMensagem] = useState('');

  async function handleLogin(e) {
    try {
      await signIn({ email, password });

      history.push('/operation');
    } catch (err) {
      setMensagem(err.response.data.error);
    }
  }

  function handleEmailInput(e) {
    setEmail(e.target.value);
  }

  function handlePasswordlInput(e) {
    setPassword(e.target.value);
  }

  return (
    <Container>
      <Content>
        <div style={{ textAlign: 'center' }}>
          <img
            src={logo}
            alt="logo"
            height="80"
            width="80"
            style={{ margin: 'auto' }}
          />
          <h2>Clube do Cliente</h2>
          <h3>Operação</h3>
          <h3>Login</h3>
        </div>

        <HeaderClient>
          <div style={{ textAlign: 'center' }}>Digite o e-mail</div>
          <InputMask value={email} onChange={e => handleEmailInput(e)} />
        </HeaderClient>

        <HeaderPassword>
          <div style={{ textAlign: 'center' }}>Digite sua senha</div>
          <InputMask type="password" onChange={e => handlePasswordlInput(e)} />
        </HeaderPassword>

        <div style={{ color: 'red', textAlign: 'center' }}>
          <span>{mensagem}</span>
        </div>

        <Footer>
          <FinishPurchase>
            <div>
              <button
                className="btn-primary"
                style={{ background: '#03DB9E' }}
                onClick={() => handleLogin()}
              >
                Login
              </button>
            </div>
          </FinishPurchase>
        </Footer>
      </Content>
    </Container>
  );
};

export default Login;
