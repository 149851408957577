import React from 'react';

import { Container } from './styles';
import formatPhoneString from '../../services/formatPhoneString';
import formatCompleteDate from '../../services/formatCompleteDate';

const PurchaseComponent = ({purchase, settings}) => {
  const hasClient = purchase.client ? true : false;

  function capitalizeFirst(str) {
    var subst = str.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    return subst;
  }

  return (
    <Container status={hasClient}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div>
          <div style={{margin: 10, fontSize: 16}}>
            {formatPhoneString(purchase.phone)}
          </div>
          <div style={{marginLeft: 10, fontSize: 13}}>
            {purchase.client ? capitalizeFirst(purchase.client.name.substring(0,25)) : "Não Cadastrado"}
          </div>
          <div style={{marginLeft: 10, paddingTop: 15, fontSize: 13}}>
            {formatCompleteDate(purchase.createdAt)}
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', float: 'right'}}>
          <div style={{margin: 10, marginLeft: 50, fontSize: 14}}>
            Total: R${purchase.total}
          </div>
          <div style={{marginLeft: 50, fontSize: 13}}>
            {purchase.cashback ? 
              `Cashback: R$${purchase.cashback}`
              :
              `Pontos: ${purchase.points} ponto(s)`
            }
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PurchaseComponent;
