import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import api from '../../services/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(() => {
    const tokenStorage = localStorage.getItem('@clube:token');

    if (tokenStorage) {
      api.defaults.headers.Authorization = `Bearer ${tokenStorage}`;
      return tokenStorage;
    }

    return '';
  });

  useEffect(() => {
    localStorage.setItem('@clube:token', userToken);
  }, [userToken]);

  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@clube:token');
    const user = localStorage.getItem('@clube:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('public/sessions/store', {
      email,
      password,
    });
    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    localStorage.setItem('@clube:token', token);
    localStorage.setItem('@clube:user', JSON.stringify(user));
    setData({ token, user });

    setUserToken(token);
  }, []);

  const updateSettings = useCallback(async settings => {
    const token = localStorage.getItem('@clube:token');

    const response = await api.put('store/settings', settings, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const user = response.data;

    localStorage.setItem('@clube:user', JSON.stringify(user));

    setData({ user, token });
    return true;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@clube:token');
    localStorage.removeItem('@clube:user');
    setUserToken('');

    setData({});
  });

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateSettings, userToken }} //, updateProfile }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    console.log('useAuth must be within a provider');
  }

  return context;
}

export { AuthProvider, useAuth };
