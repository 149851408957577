import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { TiDocumentText } from 'react-icons/ti';

// import { FiCalendar, FiCheckSquare, FiGrid } from 'react-icons/fi';
import { MdAddShoppingCart, MdHistory } from 'react-icons/md';

import { useAuth } from '../../context/auth';

import PurchaseComponent from '../../components/PurchaseComponent';

import api from '../../services/api';

import {
  Container,
  Content,
  HeaderClient,
  HeaderAction,
  Footer,
  ButtonOption,
  ButtonAction,
  ButtonGoToCart,
} from './styles';

const History = props => {
  const { user } = useAuth();
  const history = useHistory();
  const [path, setPath] = useState('');
  const [purchases, setPurchases] = useState([]);
  const [purchasesToShow, setPurchasesToShow] = useState([]);

  const settings = user && user.settings ? user.settings[0] : null;

  const [phone, setCelphone] = useState('');

  function logOut() {
    history.push('/');
  }

  function handleChangeCelphone(e) {
    setCelphone(e.target.value);
  }

  async function getPurchases() {
    try {
      const response = await api.get(`/store/purchases`);
      setPurchases(response.data.purchases);
      setPurchasesToShow(response.data.purchases);
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    getPurchases();
  }, []);

  useEffect(() => {
    if (phone.length === 15) {
      const parsedPhone = phone.replace(' ', '').replace('-', '');
      const filteredPurchases = purchases.filter(
        purchase => purchase.phone === parsedPhone,
      );
      setPurchasesToShow(filteredPurchases);
    } else {
      if (purchasesToShow.length !== purchases.length) {
        setPurchasesToShow(purchases);
      }
    }
  }, [phone]);

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <h1>{user.name}</h1>
            <button
              onClick={() => {
                logOut();
              }}
            >
              Sair
            </button>
          </header>
        </HeaderAction>

        <HeaderClient>
          <InputMask
            mask="(99) 99999-9999"
            maskChar=""
            placeholder="(__) _____-____"
            value={phone}
            onChange={e => handleChangeCelphone(e)}
          />
        </HeaderClient>

        {purchasesToShow.map(purchase => (
          <PurchaseComponent purchase={purchase} settings={settings} />
        ))}

        <Footer>
          <ButtonAction>
            <ButtonOption to={'/history'}>
              <MdHistory size={22} color="#028D65" />
              Histórico
            </ButtonOption>
            <ButtonOption to={'/operation'}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingBottom: 35,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ButtonGoToCart>
                  <MdAddShoppingCart size={30} color="white" />
                </ButtonGoToCart>
                <span>Operação</span>
              </div>
            </ButtonOption>
            <ButtonOption to={'/extract'} path={path}>
              <TiDocumentText size={22} color="#028D65" />
              Extrato
            </ButtonOption>
          </ButtonAction>
        </Footer>
      </Content>
    </Container>
  );
};

export default History;
