import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 90px;
  text-decoration: none;
  background: #ffbb33;
  color: #551800;
  display: flex;
  flex-direction: column;
  margin: 10px;

  ${props =>
    props.status ?
    css`
      background: #B6EEF6;
      color: #00420f;
    `
    :
    css`
      background: #F1B2A7;
      color: #8c2500;
    `
  };

  // justify-content: center;
  // align-items: center;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  // font-weight: bold;
  border-radius: 7px;
`;
