import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MdAddShoppingCart, MdHistory } from 'react-icons/md';
import { TiDocumentText } from 'react-icons/ti';
import { useAuth } from '../../context/auth';
import api from '../../services/api';

import {
  Container,
  Content,
  HeaderClient,
  HeaderAction,
  Footer,
  ButtonOption,
  ButtonAction,
  ButtonGoToCart,
} from './styles';
import { startOfDay, endOfDay } from 'date-fns';
import formatCompleteDate from '../../services/formatCompleteDate';

const Extract = props => {
  const { user } = useAuth();
  const history = useHistory();
  const [path, setPath] = useState('');
  const [info, setInfo] = useState(null);

  const settings = user && user.settings ? user.settings[0] : null;

  function logOut() {
    history.push('/');
  }

  async function getInfo() {
    try {
      const response = await api.get(`/store/purchases/daily`);
      setInfo(response.data);
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    getInfo();
  }, []);

  const frase = `De ${formatCompleteDate(
    startOfDay(new Date()),
  )} até ${formatCompleteDate(endOfDay(new Date()))}`;

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <h1>{user.name}</h1>
            <button
              onClick={() => {
                logOut();
              }}
            >
              Sair
            </button>
          </header>
        </HeaderAction>

        <HeaderClient>
          <h2>Extrato</h2>
          <p>{frase}</p>
        </HeaderClient>

        <div style={{ marginTop: 40, color: '#474747' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #7A7A7A',
              borderRadius: 6,
              padding: 10,
            }}
          >
            <div style={{ width: '70%' }}>
              <h3>Vendas Registradas</h3>
            </div>
            <div
              style={{
                width: '30%',
                textAlign: 'right',
                marginRight: 10,
                color: 'green',
              }}
            >
              <h3>{info && info.purchases}</h3>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #7A7A7A',
              borderRadius: 6,
              padding: 10,
              marginTop: 15,
            }}
          >
            <div style={{ width: '70%' }}>
              <h3>Total em Vendas</h3>
            </div>
            <div
              style={{
                width: '30%',
                textAlign: 'right',
                marginRight: 10,
                color: 'green',
              }}
            >
              <h3>R${info ? info.totalPurchases : '0.00'}</h3>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #7A7A7A',
              borderRadius: 6,
              padding: 10,
              marginTop: 15,
            }}
          >
            <div style={{ width: '70%' }}>
              <h3>Resgates</h3>
            </div>
            <div
              style={{
                width: '30%',
                textAlign: 'right',
                marginRight: 10,
                color: 'green',
              }}
            >
              <h3>{info ? info.rescues : '0'}</h3>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #7A7A7A',
              borderRadius: 6,
              padding: 10,
              marginTop: 15,
            }}
          >
            <div style={{ width: '70%' }}>
              <h3>
                {settings.model === 'points'
                  ? 'Pontos Resgatados'
                  : 'Cashback Resgatado'}
              </h3>
            </div>
            <div
              style={{
                width: '30%',
                textAlign: 'right',
                marginRight: 10,
                color: 'green',
              }}
            >
              <h3>
                {settings.model === 'points'
                  ? info
                    ? info.pointsRescue
                    : 0
                  : info && info.cashbackRescue
                  ? `R$${info.cashbackRescue}`
                  : 0}
              </h3>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid #7A7A7A',
              borderRadius: 6,
              padding: 10,
              marginTop: 15,
            }}
          >
            <div style={{ width: '70%' }}>
              <h3>
                {settings.model === 'points'
                  ? 'Pontos Gerados'
                  : 'Cashback Gerado'}
              </h3>
            </div>
            <div
              style={{
                width: '30%',
                textAlign: 'right',
                marginRight: 10,
                color: 'green',
              }}
            >
              <h3>
                {settings.model === 'points'
                  ? info
                    ? info.pointsGenerated
                    : 0
                  : info && info.cashbackGenerated
                  ? `R$${info.cashbackGenerated}`
                  : 0}
              </h3>
            </div>
          </div>
        </div>

        <Footer>
          <ButtonAction>
            <ButtonOption to={'/history'}>
              <MdHistory size={22} color="#028D65" />
              Histórico
            </ButtonOption>
            <ButtonOption to={'/operation'}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingBottom: 35,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ButtonGoToCart>
                  <MdAddShoppingCart size={30} color="white" />
                </ButtonGoToCart>
                <span>Operação</span>
              </div>
            </ButtonOption>
            <ButtonOption to={'/extract'} path={path}>
              <TiDocumentText size={22} color="#028D65" />
              Extrato
            </ButtonOption>
          </ButtonAction>
        </Footer>
      </Content>
    </Container>
  );
};

export default Extract;
